<template>
  <div class="taskInfo">
    <el-scrollbar>
      <!-- 能够应用span 和 span-map对象来管制栅格的大小 -->
      <el-descriptions class="margin-top" title="工程信息" :column="3" border>
        <template slot="extra">
          <el-button size="small" v-show="!infoFlag" @click="infoFlag = true">取消</el-button>
          <el-button type="warning" size="small" v-show="!infoFlag" @click="updateTaskFun('infoFlag')">修改</el-button>
          <el-button type="primary" size="small" v-show="infoFlag" @click="infoFlag = false">编辑</el-button>
        </template>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            任务名称
          </template>
          <span v-show="infoFlag"> {{ taskInfo.projectName }}</span>
          <el-input type="text" v-model="taskInfo.projectName" maxlength="35" show-word-limit name="" id="" v-show="!infoFlag" />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            任务编号
          </template>
          <span v-show="infoFlag"> {{ taskInfo.projectNo }}</span>
          <el-input type="text" v-model="taskInfo.projectNo" name="" id="" v-show="!infoFlag" />
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            地图定位
          </template>
          <span v-show="infoFlag"> {{ taskInfo.longitude + ',' + taskInfo.latitude }}</span>

          <el-input v-show="!infoFlag" class="GPS" v-model.number="taskInfo.longitude" placeholder="请输入 经度"></el-input>
          <el-input v-show="!infoFlag" class="GPS" v-model.number="taskInfo.latitude" placeholder="请输入  纬度"></el-input>
          <el-button v-show="!infoFlag" class="GPS" @click="showMap">地图定位</el-button>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            任务地址
          </template>

          <span v-show="infoFlag"> {{ taskInfo.projectAddress || '暂无' }}</span>
          <el-input type="text" v-model="taskInfo.projectAddress" name="" id="" v-show="!infoFlag" />
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            任务状态
          </template>
          {{ taskInfo.state.runningState }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            安全状态
          </template>
          {{ taskInfo.safeState.safeState }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            监管平台监测编号
          </template>

          <span v-show="infoFlag"> {{ taskInfo.supervisionPlatformNo || '暂无' }}</span>
          <el-input type="text" v-model="taskInfo.supervisionPlatformNo" name="" id="" v-show="!infoFlag" />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            任务节点数量
          </template>
          暂无节点信息
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            主机管理员
          </template>
          {{ hostAdmin.userName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            联系电话
          </template>
          {{ hostAdmin.phone }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            是否开启中值报警
          </template>

          <span v-show="infoFlag"> {{ taskInfo.fkPhoneId === 1 ? '已开启' : '关闭' || '暂无' }}</span>

          <el-switch v-model="taskInfo.fkPhoneId" :active-value="1" :inactive-value="0" v-show="!infoFlag"> </el-switch>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions class="margin-top" style="margin-top: 20px" title="工程相关文件" :column="2" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            展示图1
          </template>
          <a :href="taskInfo.flatPatternmakingOne" target="view_window" title="查看展示图1"> {{ taskInfo.flatPatternmakingOne ? ip + taskInfo.flatPatternmakingOne : '暂未上传此相关文件' }}</a>
          <!-- <el-input type="text" v-model="taskInfo.coveredArea" v-show="!parameterFlag" /> -->
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            展示图2
          </template>

          <a :href="taskInfo.flatPatternmakingTwo" target="view_window" title="查看展示图2"> {{ taskInfo.flatPatternmakingTwo ? ip + taskInfo.flatPatternmakingTwo : '暂未上传此相关文件' }}</a>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            简报
          </template>

          <a :href="taskInfo.briefReport" target="view_window" title="查看任务简报"> {{ taskInfo.briefReport ? ip + taskInfo.briefReport : '暂未上传此相关文件' }}</a>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            总报
          </template>

          <a :href="taskInfo.alwaysQuote" target="view_window" title="查看任务总报"> {{ taskInfo.alwaysQuote ? ip + taskInfo.alwaysQuote : '暂未上传此相关文件' }}</a>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            平面图(测点图)
          </template>

          <a :href="taskInfo.plan" target="view_window" title="查看平面图(测点图)"> {{ taskInfo.plan ? ip + taskInfo.plan : '暂未上传此相关文件' }}</a>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            监测方案
          </template>

          <a :href="taskInfo.monitoringProgramme" target="view_window" title="查看监测方案"> {{ taskInfo.monitoringProgramme ? ip + taskInfo.monitoringProgramme : '暂未上传此相关文件' }}</a>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            审批报告
          </template>
          <a :href="taskInfo.examinationApprovalReport" target="view_window" title="查看审批报告"> {{ taskInfo.examinationApprovalReport ? ip + taskInfo.examinationApprovalReport : '暂未上传此相关文件' }}</a>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            监测实施方案
          </template>

          <a :href="taskInfo.implementationPlan" target="view_window" title="监测实施方案"> {{ taskInfo.implementationPlan ? ip + taskInfo.implementationPlan : '暂未上传此相关文件' }}</a>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions class="margin-top" style="margin-top: 20px" title="工程参数" :column="2" border>
        <template slot="extra">
          <el-button size="small" v-show="!parameterFlag" @click="parameterFlag = true">取消</el-button>
          <el-button type="warning" size="small" v-show="!parameterFlag" @click="updateTaskFun('parameterFlag')">修改</el-button>
          <el-button type="primary" size="small" v-show="parameterFlag" @click="parameterFlag = false">编辑</el-button>
        </template>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            建筑面积 m²
          </template>
          <span v-show="parameterFlag"> {{ taskInfo.coveredArea || '暂未填写此项信息' }}</span>
          <el-input type="text" v-model="taskInfo.coveredArea" v-show="!parameterFlag" />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            支模形式
          </template>

          <span v-show="parameterFlag"> {{ taskInfo.modularForm || '暂未填写此项信息' }}</span>
          <el-input type="text" v-model="taskInfo.modularForm" v-show="!parameterFlag" />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            模板高度 m
          </template>

          <span v-show="parameterFlag"> {{ taskInfo.templateHeight || '暂未填写此项信息' }}</span>
          <el-input type="text" v-model="taskInfo.templateHeight" v-show="!parameterFlag" />
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            模板跨度 m
          </template>

          <span v-show="parameterFlag"> {{ taskInfo.templateSpan || '暂未填写此项信息' }}</span>
          <el-input type="text" v-model="taskInfo.templateSpan" v-show="!parameterFlag" />
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            模板线荷载 kN/m
          </template>

          <span v-show="parameterFlag"> {{ taskInfo.formworkLineLoad || '暂未填写此项信息' }}</span>
          <el-input type="text" v-model="taskInfo.formworkLineLoad" v-show="!parameterFlag" />
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            模板面荷载 kN/m²
          </template>

          <span v-show="parameterFlag"> {{ taskInfo.formworkSurfaceLoad || '暂未填写此项信息' }}</span>
          <el-input type="text" v-model="taskInfo.formworkSurfaceLoad" v-show="!parameterFlag" />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            模板设计沉量 mm
          </template>

          <span v-show="parameterFlag"> {{ taskInfo.templateDesignWeight || '暂未填写此项信息' }}</span>
          <el-input type="text" v-model="taskInfo.templateDesignWeight" v-show="!parameterFlag" />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            立杆设计承载力 km
          </template>

          <span v-show="parameterFlag"> {{ taskInfo.designBearingCapacityOfPole || '暂未填写此项信息' }}</span>
          <el-input type="text" v-model="taskInfo.designBearingCapacityOfPole" v-show="!parameterFlag" />
        </el-descriptions-item>
      </el-descriptions>

      <!-- 联系人列表 -->
      <div class="phoneList">
        <h2 class="title">
          联系人列表
          <el-button class="fr" type="primary " size="small" @click="addPhoneDialog">添加</el-button>
        </h2>

        <MyTable :dataSource="taskPhone" :columns="columns" border stripe :loading="loading" :total="total" :currentPage="0" :pageSize="10" @handleClick="handleClick"></MyTable>
      </div>
    </el-scrollbar>

    <el-dialog class="map-container" center title="双击选择后点击任意空白即可" :visible="mapVisible" :before-close="handleMapClose" :modal-append-to-body="true" :append-to-body="true" :fullscreen="true">
      <TianDiMap style="width: 100%; height: 100%" class="map" :addMarkerFlag="true" :lng="this.taskInfo.longitude" :lat="this.taskInfo.latitude" @addMarkerSuccess="changeLngLat"> </TianDiMap>
    </el-dialog>

    <el-dialog center class="phone" :title="tips" :visible="addPhoneFlag" :before-close="handleClose" append-to-body>
      <AddPhone :pkTaskId="pkTaskId" :id="phoneId" v-if="addPhoneFlag"> </AddPhone>
    </el-dialog>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'

const ip = window.location.origin
const TianDiMap = () => ({
  component: import('@/components/TianDiMap'),
})

import AddPhone from './AddPhone.vue'

import { findTaskByTaskId, findTaskPhone, updateTask, removePhone } from '@/api/Task.js'
import { findHostById } from '@/api/Host.js'
const columns = [
  {
    prop: 'name',
    label: '联系人',
    width: 200,
    type: 'text',
  },
  {
    prop: 'phone',
    label: '联系电话',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'edit',
    label: '修改',
    align: 'center',
    type: 'button',
    width: 120,
    buttonInfo: {
      text: '修改',
      type: '',
      size: 'mini',
    },
  },
  {
    prop: 'del',
    label: '删除',
    align: 'center',
    type: 'button',
    width: 120,
    buttonInfo: {
      text: '删除',
      type: 'danger',
      size: 'mini',
    },
  },
]
export default {
  components: { MyTable, TianDiMap, AddPhone },
  data() {
    return {
      columns,
      loading: true,
      total: 0,
      mapVisible: false,
      infoFlag: true,
      parameterFlag: true,
      addPhoneFlag: false,
      ip,
      taskInfo: {
        safeState: {
          pkSafeStateId: null,
          safeState: null,
        },
        state: {
          pkTaskRunningStateId: null,
          runningState: null,
        },
      },
      taskPhone: [],
      hostAdmin: {
        userName: null,
        phone: null,
      },
      phoneId: null,
      tips: null,
      hostId2: null,
    }
  },
  props: {
    pkTaskId: {
      type: Number,
    },
    hostId: {
      type: Number,
    },
  },
  methods: {
    async findTaskByTaskIdFun() {
      const { data: res } = await findTaskByTaskId(this.pkTaskId)

      this.taskInfo = res.data.list[0]
    },
    // 查询任务联系人
    async findTaskPhoneFun() {
      const { data: res } = await findTaskPhone(this.pkTaskId)

      this.taskPhone = res.data
      this.total = res.data.length
      this.loading = false
    },
    // 查询主机信息
    async findHostByIdFun(id) {
      const hostId = id ? id : this.hostId
      const { data: res } = await findHostById(hostId)

      this.hostAdmin.userName = res.data.list[0].user.userName
      this.hostAdmin.phone = res.data.list[0].user.phone
    },
    // 修改信息
    async updateTaskFun(type) {
      let taskInfo = {}

      if (type === 'infoFlag') {
        const { projectNo, projectName, projectAddress, supervisionPlatformNo, longitude, latitude, fkPhoneId } = this.taskInfo

        taskInfo = { projectNo, projectName, projectAddress, supervisionPlatformNo, longitude, latitude, pkTaskId: this.pkTaskId, fkPhoneId }
      } else {
        const { coveredArea, formworkLineLoad, formworkSurfaceLoad, templateDesignWeight, templateSpan, templateHeight, designBearingCapacityOfPole, modularForm } = this.taskInfo
        taskInfo = { coveredArea, formworkLineLoad, formworkSurfaceLoad, templateDesignWeight, templateSpan, templateHeight, designBearingCapacityOfPole, modularForm, pkTaskId: this.pkTaskId }
      }

      const { data: res } = await updateTask(taskInfo)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      type === 'infoFlag' ? (this.infoFlag = true) : (this.parameterFlag = true)

      this.findTaskByTaskIdFun()
    },
    // 删除联系人
    async removePhoneFun(id) {
      const { data: res } = await removePhone(id)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.findTaskPhoneFun()
    },
    handleClick(row, type) {
      switch (type) {
        case 'edit':
          this.tips = '修改报警联系人'
          this.phoneId = row.id
          this.addPhoneFlag = true
          break
        case 'del':
          console.log('del', row)
          this.removePhoneFun(row.id)
          break
      }
    },

    addPhoneDialog() {
      this.tips = '添加报警联系人'
      this.addPhoneFlag = true
    },

    showMap() {
      this.mapVisible = true
    },
    handleMapClose() {
      this.mapVisible = false
    },
    handleClose() {
      this.addPhoneFlag = false
    },
    changeLngLat(lng, lat) {
      this.taskInfo.longitude = lng - 0
      this.taskInfo.latitude = lat - 0
    },
  },
  created() {
    if (!this.pkTaskId) {
      this.$message({
        message: '操作有误，请刷新页面重试',
        type: 'error',
      })
      return
      // 仅传入工程ID 时 ，会先查询工程详情 获取到了主机ID 然后查询主机联系人信息
    } else if (this.pkTaskId && !this.hostId) {
      this.findTaskByTaskIdFun().then(() => {
        this.findHostByIdFun(this.taskInfo.fkHostId)
      })
      this.findTaskPhoneFun()
      return
    } else {
      this.findTaskByTaskIdFun()
      this.findTaskPhoneFun()
      this.findHostByIdFun()
    }
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.title {
  font-weight: 700;
  font-size: 16px;
  color: #303133;
  line-height: 50px;
  margin: 10px 0;
}
.taskInfo {
  width: 100%;

  height: 100%;
  .el-scrollbar {
    height: 100%;
  }

  .el-scrollbar ::v-deep.el-scrollbar__wrap {
    overflow: scroll;
    height: 100% !important;
    overflow-x: hidden !important;
  }

  .el-input.GPS {
    width: 120px;
  }
  .phoneList {
    .el-button {
      margin-top: 8px;
    }

    ::v-deep td,
    ::v-deep th {
      height: 40px;
      padding: 0 !important;
    }
    ::v-deep th {
      background-color: #fafafa;
    }
  }
}
.phone ::v-deep .el-dialog {
  width: 500px !important;
  height: 300px !important;
}

// .el-dialog__body {
//   padding: 0 25px;
// }
</style>
