<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="联系姓名" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">{{ id ? '立即修改' : '立即创建' }}</el-button>

        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addPhone, updatePhone, findPhone } from '@/api/Task.js'
export default {
  data() {
    return {
      ruleForm: {
        name: null,
        phone: null,
        fkTaskId: null,
      },
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
      },
    }
  },
  props: {
    pkTaskId: {
      type: Number,
    },
    id: {
      type: Number,
    },
  },
  methods: {
    async addPhoneFun() {
      const { data: res } = await addPhone([this.ruleForm])
      this.$message({
        message: '联系人' + res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.$parent.$parent.findTaskPhoneFun()
      this.$parent.$parent.handleClose()
    },
    async findPhoneFun() {
      const { data: res } = await findPhone(this.id)


      this.ruleForm = res.data
    },

    async updatePhoneFun() {
      const { data: res } = await updatePhone(this.ruleForm)
      this.$message({
        message: '联系人' + res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.$parent.$parent.findTaskPhoneFun()
      this.$parent.$parent.handleClose()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.id ? this.updatePhoneFun() : this.addPhoneFun()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose() {
      this.$parent.$parent.handleClose()
    },
  },
  created() {
    if (this.id) {
      console.log('传来的ID', this.id)
      this.findPhoneFun()
    }
    this.ruleForm.fkTaskId = this.pkTaskId
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.el-input {
  width: 300px;
}
</style>
